.pieceSelectorContainer {
    max-width: 500px;
    max-height: 100px;
    display: flex;
    flex-wrap: wrap;
}

.pieceContainer {
    width: calc(100vw/8);
    height: calc(100vw/8);
    max-width: 100px;
    max-height: 100px;
}

