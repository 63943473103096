.tile {
    width: 100%;
    height: calc(100vw/8);
    max-height: 100px;
}

.white-tile {
    background-color: #eadbba;
}

.black-tile {
    background-color: #c1a580;
}

.red-tile {
    background-color: red;
}

.selected {
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    display: inline-block;
}