.piece {
    height: 70%;
    width: 70%;
}

.tile > * > .piece {
    padding: 15%;
}

.piece:hover {
    cursor: pointer;
}



