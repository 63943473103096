.board {
    width: 800px;
    display: grid;
    grid-template-columns: 100px 100px 100px 100px 100px 100px 100px 100px;
    max-height: 800px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
}

.boardContainer {
    display: flex;
    padding: 100px;
    background-color: #f4f4f4;
}

.info-display {
    margin-left: 75px;
    text-align: left;
}

.info-display > h1{
    font-size: 3rem;
    margin-top: 0;
}

.info-display > h2 {
    font-size: 2rem;
}

.info-display > h3 {
    font-size: 1.25rem;
}

.warning-text{
    color: red;
}

button {
    margin-right: 10px;
}

label {
    margin-right: 10px;
}

@media only screen and (max-width: 1250px) {
    .boardContainer {
        flex-direction: column-reverse;
    }

    .info-display {
        margin-left: 0px;
        margin-bottom: 50px;
    }
}

@media only screen and (max-width: 900px) {
    .board {
        width: auto;
        display: grid;
        grid-template-columns: auto auto auto auto auto auto auto auto;
    }

    .info-display {
        margin-left: 0px;
        margin-bottom: 2vh;
    }

    .info-display > h3 {
        margin: 1vh;
    }

    .info-display > h2 {
        margin: 0;
        margin-bottom: 2vh;
    }

    .info-display > h1 {
        margin-bottom: 2vh;
    }

    .warning-text{
        margin-left: 1vh;
    }

    .boardContainer {
        padding: 10px;
    }


}

